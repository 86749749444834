<template>  
<div >
    <v-app class="fullLayout gradientBackground" >
        <div >
            <TopBarForThirdPersonVideoCall class="layout" />
        </div>
        <div>
            <v-main class="body">
            <div class="routerView">             
                <div>
        <v-dialog v-model="roomFullDialog" max-width="400" persistent>
            <v-card>
                <v-card-title class="error-title pa-4">
                    <v-icon left color="error" class="mr-2">
                        mdi-alert-circle
                    </v-icon>
                    Room Capacity Reached
                </v-card-title>

                <v-card-text class="pa-4">
                    <p class="text-body-1">
                        We apologize, but this video call room has reached its maximum capacity of 3 participants.
                        Please contact CE Team for assistance.
                    </p>
                </v-card-text>

                <v-card-actions class="pa-4">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="outlined" class="ml-2" @click="goBack" elevation="0" style="background-color: #1467BF;">
                        Go Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div style="position: relative; min-width: 300px; min-height: 300px;">
            <div id="video-container" class="row remote_video_container">
                <!-- <div class="remoteParticipants" style="display: flex; flex:1; width: 100% !important;" > -->
                <div id="remote-media-div-remote" style="
            background-color: white; border-radius: 16px;
            width: 100% !important; 
            display: flex !important; justify-content: center;"></div>
                <!-- </div>   -->
            </div>
            <div id="remote-media-div-local"></div>


            <div style="display:flex; flex-direction: row;justify-content: space-evenly; margin : 0px 20px 0px 0px !important "
                v-if="callConnected">
                <div style="margin:2px" v-if="deviceIDList.length > 1">
                    <v-btn elevation="8" :disabled="isCameraSwitched" v-if="videoBtnFlag"
                        style=" background: #FFFFFF;border-radius: 8px; color:white;position: absolute; bottom: 0;margin-bottom: 30px;"
                        @click="switchCamera(activeRoom)" icon>
                        <img src="https://s3iconimages.mymedicine.com.mm/switchCamera.svg" />
                    </v-btn>
                </div>
                <div style="margin:2px">
                    <v-btn elevation="8" v-if="videoBtnFlag"
                        style="  background: #FFFFFF;border-radius: 8px; color:white;position: absolute; bottom: 0;margin-bottom: 30px;"
                        @click="turnVideoOff" icon>
                        <img src="https://s3iconimages.mymedicine.com.mm/CameraOn.svg" />
                    </v-btn>
                    <v-btn elevation="8" v-else
                        style="  background: #FFFFFF;border-radius: 8px; color:white;position: absolute; bottom: 0;margin-bottom: 30px;"
                        @click="turnVideoOn" icon>
                        <img src="https://s3iconimages.mymedicine.com.mm/CameraOff.svg" />
                    </v-btn>
                </div>
                <div style="margin:2px">
                    <v-btn elevation="8" v-if="audioBtnFlag"
                        style="  background: #FFFFFF;border-radius: 8px; color:white;position: absolute; bottom: 0;margin-bottom: 30px;"
                        @click="turnAudioOff" id="muteAudio" icon>
                        <img src="https://s3iconimages.mymedicine.com.mm/micOn.svg" />
                    </v-btn>
                    <v-btn elevation="8" v-else
                        style="  background: #FFFFFF;border-radius: 8px; color:white;position: absolute; bottom: 0;margin-bottom: 30px;"
                        @click="turnAudioOn" icon><img src="https://s3iconimages.mymedicine.com.mm/micOff.svg" />
                    </v-btn>
                </div>
                <div style="margin:2px">
                    <v-btn elevation="8" id="disconnectBtn"
                        style="background: #EB5757; border-radius: 8px; position: absolute; bottom: 0; margin-bottom: 30px;"
                        @click="endTwilioVideoCall" icon>
                        <img src="https://s3iconimages.mymedicine.com.mm/leaveCall.svg" />
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
            </div>
            </v-main>
        </div>
    </v-app>
</div>
</template>

<script>
import { defineComponent } from 'vue';
import axios from "axios";
import * as Twilio from "twilio-video";
import TopBarForThirdPersonVideoCall from './topBarForThirdPersonVideoCall.vue';


export default defineComponent({
    name: 'ThirdPersonVideoCallPage',
    components: {
        TopBarForThirdPersonVideoCall
    },
    data() {
        return {
            token: '',
            videoConstraint: {},
            currentFacingMode: "user",
            customer_name: "",
            ConnectTrack: null,
            twilioRoomName: '',
            activeRoom: null,
            remoteParticipant: null,
            container: null,
            callConnected: false,
            deviceIDList: [],
            isCameraSwitched: false,
            videoBtnFlag: true,
            audioBtnFlag: true,
            waitTimer: true,
            bookingID: '',
            roomFullDialog: false
        };
    },
    async mounted() {
        try {
            this.token = this.$route.params.token;
            this.bookingID = this.$route.params.appointmentID;
            this.container = document.getElementById("video-container");
            this.startRoom();
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        async joinVideoRoom(roomName, token) {
            try {
                let tracks;
                try {
                    tracks = await Twilio.createLocalTracks({
                        audio: true,
                        video: {
                            facingMode: "user"
                        },
                    });
                } catch (error) {
                    alert('Not able to connect media');
                    throw error;
                }

                let room = await Twilio.connect(token, { name: roomName, tracks });
                this.activeRoom = room;
                console.log(room, "prakers");
                console.log(this.activeRoom.name);

                // this.activeRoom.disconnect();
                this.callConnected = true;
                this.renderLocalParticpantMediaInTheRoom(room);
                this.handleAlreadyConnectedRemoteParticpant(room);
                this.renderNewRemotePartipantOnJoining(room);
                this.handleDisconnectedParticipant(room);
                return room;

            } catch (error) {
                console.log(error);
                if (error.code === 53105 && error.message === 'Room contains too many Participants') {
                    // alert('Maximum Of 3 Particiapts Allowed, Redirecting ');
                    this.roomFullDialog = true
                }
            }
        },
        async startRoom() {
            const room = await this.joinVideoRoom(this.twilioRoomName, this.token);
            await this.joinVideoCallLogEntry('CUSTOMER', this.bookingID);

            this.activeRoom = room;
        },
        removeElementByID(elementID) {
            let element = document.getElementById(elementID);
            while (element) {
                element.remove(); // Removes the element from the DOM
                console.log(`Element with ID "${elementID}" removed.`);
                element = document.getElementById(elementID);
            }
        },
        handleAlreadyConnectedRemoteParticpant(room) {
            room.participants.forEach(participant => {
                participant.tracks.forEach(publication => {
                    if (publication.track) {
                        const mainDiv = document.createElement("div");
                        mainDiv.id = participant.identity;

                        console.log('handleAlreadyConnectedRemoteParticpant() mainDiv.id: ', mainDiv.id);

                        mainDiv.appendChild(publication.track.attach());
                        document.getElementById('remote-media-div-remote').appendChild(mainDiv);
                    }
                });

                participant.on('trackSubscribed', track => {
                    const mainDiv = document.createElement("div");
                    mainDiv.id = participant.identity;
                    mainDiv.appendChild(track.attach());
                    document.getElementById('remote-media-div-remote').appendChild(mainDiv);
                });
            });
        },
        renderLocalParticpantMediaInTheRoom(room) {
            room.localParticipant.tracks.forEach(publication => {
                if (publication.track) {
                    const mainDiv = document.createElement("div");
                    mainDiv.id = room.localParticipant.identity;

                    console.log('renderLocalParticpantMediaInTheRoom() mainDiv.id: ', mainDiv.id);

                    mainDiv.appendChild(publication.track.attach());
                    document.getElementById('remote-media-div-local').appendChild(mainDiv);
                }
            });
            navigator.mediaDevices.enumerateDevices().then((cams) => {
                cams.forEach(mediaDevice => {
                    if (mediaDevice.kind === 'videoinput') {
                        this.deviceIDList.push({ name: mediaDevice.label, deviceID: mediaDevice.deviceId });
                    }
                });
            });
        },
        renderNewRemotePartipantOnJoining(room) {
            room.on('participantConnected', participant => {
                console.log(`A remote Participant connected: ${participant}`);
                console.log(`Participant "${participant.identity}" connected`);
                participant.tracks.forEach(publication => {
                    if (publication.isSubscribed) {
                        const track = publication.track;
                        const mainDiv = document.createElement("div");
                        mainDiv.id = participant.identity;

                        console.log('renderNewRemotePartipantOnJoining() mainDiv.id: ', mainDiv.id);

                        mainDiv.appendChild(track.attach());
                        document.getElementById('remote-media-div-remote').appendChild(mainDiv);
                    }
                });

                participant.on('trackSubscribed', track => {
                    const mainDiv = document.createElement("div");
                    mainDiv.id = participant.identity;
                    mainDiv.appendChild(track.attach());
                    document.getElementById('remote-media-div-remote').appendChild(mainDiv);
                });
            });
        },
        handleDisconnectedParticipant(room) {
            room.on('participantDisconnected', participant => {
                console.log(`A remote Participant disconnected: ${participant.identity}`);
                this.removeElementByID(participant.identity)

            });
        },
        async switchCamera(room) {
            this.isCameraSwitched = true
            const cameraTrack = this.ConnectTrack.find(track => track.kind === 'video');
            this.currentFacingMode = this.currentFacingMode === "user" ? "environment" : "user"
            cameraTrack.restart({ facingMode: this.currentFacingMode });
            this.isCameraSwitched = false
        },
        turnVideoOff() {
            const room = this.activeRoom.__v_raw || this.activeRoom;
            room.localParticipant.videoTracks.forEach(publication => {
                publication.track.disable();
            });
            this.videoBtnFlag = false;
        },
        turnVideoOn() {
            const room = this.activeRoom.__v_raw || this.activeRoom;
            room.localParticipant.videoTracks.forEach(publication => {
                publication.track.enable();
            });
            this.videoBtnFlag = true;
        },
        turnAudioOff() {
            const room = this.activeRoom.__v_raw || this.activeRoom;
            room.localParticipant.audioTracks.forEach(publication => {
                publication.track.disable();
            });
            this.audioBtnFlag = false;

        },
        turnAudioOn() {
            const room = this.activeRoom.__v_raw || this.activeRoom;
            room.localParticipant.audioTracks.forEach(publication => {
                publication.track.enable();
            });
            this.audioBtnFlag = true;
        },
        endTwilioVideoCall() {
            this.exitVideoCallLogEntry('CUSTOMER', this.bookingID)
            alert('Thanks For Choosing MyMedicine')
            this.goBack();
        },
        refreshPage() {
            this.$router.go();
        },
        joinVideoCallLogEntry(userType, booking_id) {
            // axios.post(`${process.env.VUE_APP_BACKEND_URL}/joinCallEntryCPI`, {
            //     userType: userType,
            //     typeOfUser: userType,
            //     bookingId: booking_id
            // });
        },
        exitVideoCallLogEntry(userType, booking_id) {
            // axios.post(`${process.env.VUE_APP_BACKEND_URL}/exitCallLogEntryCPI`, {
            //     userType: userType,
            //     typeOfUser: userType,
            //     bookingId: booking_id
            // });
        },
        goBack() {
            this.$router.push({
                name: 'videoCallEndPage'
            });
        },
    },
    beforeDestroy() {
        if (this.activeRoom != null) {
            const room = this.activeRoom.__v_raw || this.activeRoom;
            room.localParticipant.tracks.forEach((publication) => {
                const track = publication.track;
                if (track) {
                    track.stop();
                    console.log(`Stopped track: ${track.kind}`);
                }
            });
            room.disconnect();
            this.activeRoom = null;
        }
    }
});
</script>

<style scoped>
#remote-media-div-remote>>>video {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    border-radius: 20px !important;

    @media (min-width: 1300px) {
        width: 620px !important;
    }

    @media (min-width: 1024px) and (max-width: 1299px) {
        width: 500px !important;
    }

    @media (min-width: 769px) and (max-width: 1023px) {
        width: 550px !important;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 450px !important;
    }

    @media (max-width: 480px) {
        width: 350px !important;
    }
}

#remote-media-div-local {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

#remote-media-div-local>>>video {
    /* width: 200px !important; */
    border-radius: 8px;

    @media (min-width: 769px) {
        width: 180px !important;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 150px !important;
    }

    @media (max-width: 480px) {
        width: 125px !important;
    }
}

.video-container {
    display: flex;
    flex-wrap: wrap;
}

.remote_video_container {
    /* background: red; */
    /* min-width: 480px; */
    position: relative;

    @media (min-width: 1300px) {
        /* width: 1000px !important; */
        width: 100% !important;
    }

    @media (min-width: 1024px) and (max-width: 1299px) {
        /* width: 1000px !important; */
        width: 100% !important;
    }

    @media (min-width: 769px) and (max-width: 1023px) {
        width: 750px !important;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 480px !important;
    }

    @media (max-width: 480px) {
        /* width: 450px !important; */
        width: 100% !important;
    }
}

#remote-media-div-local,
#remote-media-div-remote {
    display: flex;
    flex-wrap: wrap;
}

.callControlsPositionClass {
    position: absolute;
    bottom: 0px;
}
.fullLayout {
    display: flex; flex-direction: column; justify-content: center;
}
.home {
  width: 100%;
  height: 850px;
  background: linear-gradient(135deg, #FFDDDE -0.78%, #CFECFF 99.22%) !important;
  background-color: rebeccapurple !important;
}
.layout{
    /* position: sticky;
    position: -webkit-sticky;  */
    top: 0em;
    width: 100%;
    height: 60px;
}
.routerView{
    height: calc(100vh - 60px) !important;
    overflow-y: auto !important;
    overflow-x: hidden;
}
.body{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    
    margin-top: 70px;
    /* padding-top: 100px; */
    /* height: 500px !important; */
    /* width: 800px; */
    /* background-color: white; */
}
.gradientBackground {
    background: linear-gradient(135deg, #FFDDDE -0.78%, #CFECFF 99.22%) !important;
}
</style>
